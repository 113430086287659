#header {
    position: fixed;
    z-index: 999;
    background: rgba(0,0,0,0.65);
    border-bottom: 1px solid transparent;
    transition: border .5s cubic-bezier(0.455,0.03,0.515,0.955), background .5s cubic-bezier(0.455,0.03,0.515,0.955);
    height: 80px;
    padding: 0 12px 0 48px;
    width: 100%;
  }
  #header.home-nav-bottom {
    background: rgba(255,255,255,0.9);
    border-bottom-color: #ebedee;
  }
  #header.home-nav-bottom .search {
    border-left-color: #ebedee;
  }
  #header.home-nav-bottom a {
    color: rgba(0,0,0,.65);
  }
  #header .header-link {
    color: rgba(0,0,0,.65);
  }
  #header .ant-menu-item-active .header-link {
    color: #ebedee;
  }
  #logo {
    float: left;
    height: 80px;
    line-height: 80px;
  }
  #logo img {
    height: 60px;
    line-height: 80px;
    width: 60px;
    margin-right: 8px;
  }
  #logo span {
    float: right;
    font-size: 16px;
    font-family: 'Raleway', 'Hiragino Sans GB', sans-serif;
    height: 80px;
    line-height: 80px;
    text-transform: uppercase;
  }
  #search-box {
    border-left: 1px solid #ebedee;
    float: left;
    height: 22px;
    line-height: 22px;
    padding-left: 30px;
    margin: 29px auto 0;
  }
  #search-box .ant-select {
    font-size: 14px;
    margin-top: -3px;
    width: 200px;
  }
  #search-box .ant-select-selection {
    border: 0;
    box-shadow: none;
  }
  #search-box .ant-input {
    border: 0;
    box-shadow: none;
  }
  .header-lang-button,
  .version {
    float: right;
    margin-top: 29px;
    margin-left: 10px;
  }
  .header-lang-button {
    color: rgba(0,0,0,.65);
    border-color: #d9d9d9;
  }
  .version {
    margin-left: 16px;
  }
  #nav {
    border: 0;
    float: right;
    font-size: 16px;
    font-family: Lato, Helvetica Neue For Number;
  }
  #nav li {
    height: 80px;
    line-height: 80px;
    min-width: 82px;
    text-align: center;
    border-bottom-width: 3px;
  }
  #nav li.ant-menu-item-selected a {
    color: #ebedee;
    font-weight: bold;
  }
  .component-select.ant-select-dropdown {
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 8px rgba(0,0,0,0.25);
    font-size: 14px;
  }
  .component-select .ant-select-dropdown-menu {
    max-height: 200px;
  }
  .component-select .ant-select-dropdown-menu-item {
    border-radius: 0 !important;
  }
  .component-select .ant-component-decs {
    font-size: 12px;
    position: absolute;
    color: #aaa;
    right: 16px;
  }
  #header .header-lang-button {
    color: #fff;
    border-color: #fff;
  }
  #header .ant-select-selection,
  #header .ant-menu {
    background: transparent;
  }
  #header .ant-select-search__field {
    color: #eee;
  }
  #header .ant-select-arrow {
    color: #fff;
  }
  #header .ant-select-selection__placeholder {
    color: rgba(255,255,255,0.57);
  }
  #header.home-nav-white .ant-select-search__field {
    color: rgba(0,0,0,0.65);
  }
  #header.home-nav-white .ant-select-selection__placeholder {
    color: #cccccc;
  }
  #header.home-nav-white {
    background: rgba(255,255,255,0.91);
    border-bottom-color: #ebedee;
  }
  .home-nav-white #search-box {
    border-left-color: #ebedee;
  }
  .home-nav-white #nav li {
    color: rgba(0,0,0,0.65);
  }
  #header.home-nav-white .header-lang-button:not(:hover) {
    color: rgba(0,0,0,0.65);
    border-color: #d9d9d9;
  }
  #header.home-nav-white .version > .ant-select-selection {
    color: rgba(0,0,0,0.65);
  }
  #header.home-nav-white .version > .ant-select-selection:not(:hover) {
    border-color: #d9d9d9;
  }
  #header.home-nav-white .version .ant-select-arrow {
    color: rgba(0,0,0,0.45);
  }
  .nav-phone-icon:before {
    background: #eee;
    box-shadow: 0 7px 0 0 #eee, 0 14px 0 0 #eee;
  }
  .home-nav-white .nav-phone-icon:before {
    background: #777;
    box-shadow: 0 7px 0 0 #777, 0 14px 0 0 #777;
  }
  #nav li {
    color: #eee;
    transition: all 0.5s cubic-bezier(0.455,0.03,0.515,0.955);
  }
  #nav a.header-link {
    color: #fff;
  }
  .home-nav-white #nav a.header-link {
    color: rgba(0,0,0,0.65);
  }
  