.btnContainer{
    display: flex;
    justify-content: flex-end;
}
.highlightItem{
    background:#efefef;
}
.normalItem{
    background:#ffffff;
}
.contentFrame{
    margin:70px 250px 50px 250px;
    padding:30px;
    background:#ffffff;
    border:solid 1px #efefef;
}