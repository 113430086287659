.menuRight{
    justify-content: flex-end;
}


body{
min-height: 100%;  
}

.navMenu{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}