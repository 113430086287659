body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#header {
    position: fixed;
    z-index: 999;
    background: rgba(0,0,0,0.65);
    border-bottom: 1px solid transparent;
    transition: border .5s cubic-bezier(0.455,0.03,0.515,0.955), background .5s cubic-bezier(0.455,0.03,0.515,0.955);
    height: 80px;
    padding: 0 12px 0 48px;
    width: 100%;
  }
  #header.home-nav-bottom {
    background: rgba(255,255,255,0.9);
    border-bottom-color: #ebedee;
  }
  #header.home-nav-bottom .search {
    border-left-color: #ebedee;
  }
  #header.home-nav-bottom a {
    color: rgba(0,0,0,.65);
  }
  #header .header-link {
    color: rgba(0,0,0,.65);
  }
  #header .ant-menu-item-active .header-link {
    color: #ebedee;
  }
  #logo {
    float: left;
    height: 80px;
    line-height: 80px;
  }
  #logo img {
    height: 60px;
    line-height: 80px;
    width: 60px;
    margin-right: 8px;
  }
  #logo span {
    float: right;
    font-size: 16px;
    font-family: 'Raleway', 'Hiragino Sans GB', sans-serif;
    height: 80px;
    line-height: 80px;
    text-transform: uppercase;
  }
  #search-box {
    border-left: 1px solid #ebedee;
    float: left;
    height: 22px;
    line-height: 22px;
    padding-left: 30px;
    margin: 29px auto 0;
  }
  #search-box .ant-select {
    font-size: 14px;
    margin-top: -3px;
    width: 200px;
  }
  #search-box .ant-select-selection {
    border: 0;
    box-shadow: none;
  }
  #search-box .ant-input {
    border: 0;
    box-shadow: none;
  }
  .header-lang-button,
  .version {
    float: right;
    margin-top: 29px;
    margin-left: 10px;
  }
  .header-lang-button {
    color: rgba(0,0,0,.65);
    border-color: #d9d9d9;
  }
  .version {
    margin-left: 16px;
  }
  #nav {
    border: 0;
    float: right;
    font-size: 16px;
    font-family: Lato, Helvetica Neue For Number;
  }
  #nav li {
    height: 80px;
    line-height: 80px;
    min-width: 82px;
    text-align: center;
    border-bottom-width: 3px;
  }
  #nav li.ant-menu-item-selected a {
    color: #ebedee;
    font-weight: bold;
  }
  .component-select.ant-select-dropdown {
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 8px rgba(0,0,0,0.25);
    font-size: 14px;
  }
  .component-select .ant-select-dropdown-menu {
    max-height: 200px;
  }
  .component-select .ant-select-dropdown-menu-item {
    border-radius: 0 !important;
  }
  .component-select .ant-component-decs {
    font-size: 12px;
    position: absolute;
    color: #aaa;
    right: 16px;
  }
  #header .header-lang-button {
    color: #fff;
    border-color: #fff;
  }
  #header .ant-select-selection,
  #header .ant-menu {
    background: transparent;
  }
  #header .ant-select-search__field {
    color: #eee;
  }
  #header .ant-select-arrow {
    color: #fff;
  }
  #header .ant-select-selection__placeholder {
    color: rgba(255,255,255,0.57);
  }
  #header.home-nav-white .ant-select-search__field {
    color: rgba(0,0,0,0.65);
  }
  #header.home-nav-white .ant-select-selection__placeholder {
    color: #cccccc;
  }
  #header.home-nav-white {
    background: rgba(255,255,255,0.91);
    border-bottom-color: #ebedee;
  }
  .home-nav-white #search-box {
    border-left-color: #ebedee;
  }
  .home-nav-white #nav li {
    color: rgba(0,0,0,0.65);
  }
  #header.home-nav-white .header-lang-button:not(:hover) {
    color: rgba(0,0,0,0.65);
    border-color: #d9d9d9;
  }
  #header.home-nav-white .version > .ant-select-selection {
    color: rgba(0,0,0,0.65);
  }
  #header.home-nav-white .version > .ant-select-selection:not(:hover) {
    border-color: #d9d9d9;
  }
  #header.home-nav-white .version .ant-select-arrow {
    color: rgba(0,0,0,0.45);
  }
  .nav-phone-icon:before {
    background: #eee;
    box-shadow: 0 7px 0 0 #eee, 0 14px 0 0 #eee;
  }
  .home-nav-white .nav-phone-icon:before {
    background: #777;
    box-shadow: 0 7px 0 0 #777, 0 14px 0 0 #777;
  }
  #nav li {
    color: #eee;
    transition: all 0.5s cubic-bezier(0.455,0.03,0.515,0.955);
  }
  #nav a.header-link {
    color: #fff;
  }
  .home-nav-white #nav a.header-link {
    color: rgba(0,0,0,0.65);
  }
  

.banner-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    background: url(/static/media/home.c2d35066.jpg) no-repeat center / cover; 
    background-size:100% 100%;
  }
  .banner-text-wrapper {
    position: absolute;
    left: 13%;
    top: 53%;
    color: #fff;
    text-align: right;
    font-family: Lato;
  }
  .banner-text-wrapper h2 {
    font-size: 60px;
    font-weight: 700;
    font-family: 'Raleway', 'Hiragino Sans GB';
    white-space: nowrap;
    color: #fff;
    margin-bottom: 8px;
  }
  .banner-text-wrapper h2 p {
    font-size: 30px;
    font-weight: 200;
    color: #ff3171;
    display: inline-block;
    margin: 0;
  }
  .banner-text-wrapper .line {
    width: 0.8px;
    height: 76px;
    position: absolute;
    background: rgba(255,255,255,0.44);
    background-image: -o-linear-gradient(top,rgba(255,255,255,0.03),rgba(255,255,255,0.6),rgba(255,255,255,0.03));
    top: 16px;
    right: -25px;
  }
  .banner-text-wrapper > p {
    margin: 10px auto 24px;
    font-size: 16px;
  }
  .banner-text-wrapper .start-button {
    margin-bottom: 24px;
    text-align: right;
  }
  .banner-text-wrapper .start-button > a {
    display: inline-block;
    height: 40px;
    padding: 0 16px;
    font-weight: 500;
    border-radius: 5;
    border: 1px solid #000;
    text-decoration: none;
    color: #0ae;
    font-size: 15px;
    background: transparent;
    transition: all .45s;
    text-align: center;
    line-height: 36px;
    margin-left: 8px;
  }
  .banner-text-wrapper .start-button > a:hover {
    color: #fff;
    background: #000;
  }
  .banner-text-wrapper .github-btn {
    float: right;
    line-height: 14px;
    text-align: left;
  }
  .banner-text-wrapper .github-btn a:hover {
    color: #333;
  }
  
  .down {
    text-align: center;
    position: absolute;
    bottom: 30px;
    color: rgba(255,255,255,0.75);
    left: 50%;
    margin-left: -7px;
    -webkit-animation: upDownMove 1.2s ease-in-out infinite alternate;
            animation: upDownMove 1.2s ease-in-out infinite alternate;
  }
  .page {
    min-height: 300px;
    height: 50vh;
  }
  .content-wrapper {
    width: 100%;
    max-width: 1500px;
    margin: auto;
    overflow: hidden;
    background: #fff;
  }
  .content-wrapper .image-wrapper {
    width: 65%;
    float: left;
    position: relative;
  }
  .content-wrapper .text-wrapper {
    width: 35%;
    float: left;
    position: relative;
    top: 20%;
    left: -30px;
    font-family: Lato;
    z-index: 1;
  }
  .content-wrapper .text-wrapper h2,
  .content-wrapper .text-wrapper-bottom h2 {
    font-size: 32px;
    color: rgba(0,0,0,0.65);
    font-weight: normal;
    white-space: nowrap;
  }
  .content-wrapper .text-wrapper p {
    margin: 10px 0 20px;
    font-size: 16px;
    line-height: 28px;
    color: #999;
  }
  .content-wrapper .left-text {
    padding-left: 10%;
  }
  .image1 {
    background: url("https://t.alipayobjects.com/images/T1Ch8kXfpdXXXXXXXX.png") no-repeat right / 841px;
    height: 511px;
    top: 50%;
    margin-top: -256px;
  }
  .image2 {
    background: url("https://t.alipayobjects.com/images/T1r5RkXotXXXXXXXXX.png") no-repeat left / 800px;
    height: 474px;
    top: 50%;
    margin-top: -266px;
  }
  .image3 {
    padding-right: 10%;
    background: url("https://t.alipayobjects.com/images/T1nx0kXdFfXXXXXXXX.png") no-repeat right / 639px;
    background-origin: content-box;
    height: 500px;
    top: 50%;
    margin-top: -250px;
  }
  .image4 {
    background: url("https://t.alipayobjects.com/images/T1lyJkXg4aXXXXXXXX.png") no-repeat center / 615px;
    height: 364px;
    margin: auto;
  }
  .text-wrapper-bottom {
    text-align: center;
    margin: 10% auto 40px;
  }
  .text-wrapper-bottom p {
    margin: 20px auto;
    font-size: 16px;
    line-height: 28px;
    color: #999;
  }
  
footer.dark {
    background-color: #000;
    color: rgba(255,255,255,0.65);
  }
  footer.dark a {
    color: rgba(255,255,255,0.9);
  }
  footer.dark h2{
    color: #ffffff;
  }
  footer.dark h2 > span {
    color: #ffffff;
  }
  footer.dark h4{
    color: #ffffff;
  }
  footer.dark h4 > span {
    color: #ffffff;
  }
  footer.dark .bottom-bar {
    border-top: 1px solid rgba(255,255,255,0.25);
    overflow: hidden;
  }
  footer {
    border-top: 1px solid #000;
    clear: both;
    font-size: 14px;
    background: #fff;
    position: relative;
    z-index: 100;
    color: #fff;
    box-shadow: 0 1000px 0 1000px #fff;
  }
  footer .ant-row {
    text-align: center;
  }
  footer .ant-row .footer-center {
    display: inline-block;
    text-align: left;
  }
  footer .ant-row .footer-center > h2 {
    font-size: 16px;
    margin: 0 auto 24px;
    font-weight: 500;
    position: relative;
  }
  footer .ant-row .footer-center > h2 > .title-icon {
    width: 27px;
    margin-right: 16px;
  }
  footer .ant-row .footer-center > h2 > .anticon {
    font-size: 16px;
    position: absolute;
    left: -22px;
    top: 3px;
    color: #aaa;
  }
  footer .ant-row .footer-center > h4 {
    font-size: 20px;
    margin: 0 auto 24px;
    font-weight: 700;
    position: relative;
  }
  footer .ant-row .footer-center > h4 > .title-icon {
    width: 27px;
    margin-right: 16px;
  }
  footer .ant-row .footer-center > h4 > .anticon {
    font-size: 16px;
    position: absolute;
    left: -22px;
    top: 3px;
    color: #aaa;
  }
  footer .ant-row .footer-center > div {
    margin: 12px 0;
  }
  footer .footer-wrap {
    position: relative;
    padding: 86px 144px 93px 144px;
  }
  footer .bottom-bar {
    border-top: 1px solid #000;
    text-align: right;
    padding: 16px 144px;
    margin: 0;
    line-height: 32px;
  }
  footer .bottom-bar a {
    color: rgba(255,255,255,0.65);
  }
  footer .bottom-bar a:hover {
    color: #fff;
  }
  footer .bottom-bar .translate-button {
    text-align: left;
  }
  .logo_font{
    
    text-align: left;
       color: #FFFFFF66;
  }
  
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: #fff;
  }
  #components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }
  .ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  .clean{
    margin-left: 30px;
  }
.btnContainer{
    display: flex;
    justify-content: flex-end;
}
.highlightItem{
    background:#efefef;
}
.normalItem{
    background:#ffffff;
}
.contentFrame{
    margin:70px 250px 50px 250px;
    padding:30px;
    background:#ffffff;
    border:solid 1px #efefef;
}
.root{
    min-height: 100%;
}
.menuRight{
    justify-content: flex-end;
}


body{
min-height: 100%;  
}

.navMenu{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.menuRight{
    justify-content: flex-end;
}


body{
min-height: 100%;  
}

.navMenu{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


tbody{
    font-size: 8px;
}
   


.editable-cell {
    position: relative;
  }
  
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }
  
  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #4c33cd;
    border-radius: 2px;
  }
  
  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #4c33cd;
  }





.site-form-in-drawer-wrapper {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
    border-top: 1px solid #e9e9e9;
  }

