
.banner-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    background: url("../assets/home.jpg") no-repeat center / cover; 
    background-size:100% 100%;
  }
  .banner-text-wrapper {
    position: absolute;
    left: 13%;
    top: 53%;
    color: #fff;
    text-align: right;
    font-family: Lato;
  }
  .banner-text-wrapper h2 {
    font-size: 60px;
    font-weight: 700;
    font-family: 'Raleway', 'Hiragino Sans GB';
    white-space: nowrap;
    color: #fff;
    margin-bottom: 8px;
  }
  .banner-text-wrapper h2 p {
    font-size: 30px;
    font-weight: 200;
    color: #ff3171;
    display: inline-block;
    margin: 0;
  }
  .banner-text-wrapper .line {
    width: 0.8px;
    height: 76px;
    position: absolute;
    background: rgba(255,255,255,0.44);
    background-image: -webkit-linear-gradient(top,rgba(255,255,255,0.03),rgba(255,255,255,0.6),rgba(255,255,255,0.03));
    background-image: -moz-linear-gradient(top,rgba(255,255,255,0.03),rgba(255,255,255,0.6),rgba(255,255,255,0.03));
    background-image: -ms-linear-gradient(top,rgba(255,255,255,0.03),rgba(255,255,255,0.6),rgba(255,255,255,0.03));
    background-image: -o-linear-gradient(top,rgba(255,255,255,0.03),rgba(255,255,255,0.6),rgba(255,255,255,0.03));
    top: 16px;
    right: -25px;
  }
  .banner-text-wrapper > p {
    margin: 10px auto 24px;
    font-size: 16px;
  }
  .banner-text-wrapper .start-button {
    margin-bottom: 24px;
    text-align: right;
  }
  .banner-text-wrapper .start-button > a {
    display: inline-block;
    height: 40px;
    padding: 0 16px;
    font-weight: 500;
    border-radius: 5;
    border: 1px solid #000;
    text-decoration: none;
    color: #0ae;
    font-size: 15px;
    background: transparent;
    transition: all .45s;
    text-align: center;
    line-height: 36px;
    margin-left: 8px;
  }
  .banner-text-wrapper .start-button > a:hover {
    color: #fff;
    background: #000;
  }
  .banner-text-wrapper .github-btn {
    float: right;
    line-height: 14px;
    text-align: left;
  }
  .banner-text-wrapper .github-btn a:hover {
    color: #333;
  }
  
  .down {
    text-align: center;
    position: absolute;
    bottom: 30px;
    color: rgba(255,255,255,0.75);
    left: 50%;
    margin-left: -7px;
    animation: upDownMove 1.2s ease-in-out infinite alternate;
  }
  .page {
    min-height: 300px;
    height: 50vh;
  }
  .content-wrapper {
    width: 100%;
    max-width: 1500px;
    margin: auto;
    overflow: hidden;
    background: #fff;
  }
  .content-wrapper .image-wrapper {
    width: 65%;
    float: left;
    position: relative;
  }
  .content-wrapper .text-wrapper {
    width: 35%;
    float: left;
    position: relative;
    top: 20%;
    left: -30px;
    font-family: Lato;
    z-index: 1;
  }
  .content-wrapper .text-wrapper h2,
  .content-wrapper .text-wrapper-bottom h2 {
    font-size: 32px;
    color: rgba(0,0,0,0.65);
    font-weight: normal;
    white-space: nowrap;
  }
  .content-wrapper .text-wrapper p {
    margin: 10px 0 20px;
    font-size: 16px;
    line-height: 28px;
    color: #999;
  }
  .content-wrapper .left-text {
    padding-left: 10%;
  }
  .image1 {
    background: url("https://t.alipayobjects.com/images/T1Ch8kXfpdXXXXXXXX.png") no-repeat right / 841px;
    height: 511px;
    top: 50%;
    margin-top: -256px;
  }
  .image2 {
    background: url("https://t.alipayobjects.com/images/T1r5RkXotXXXXXXXXX.png") no-repeat left / 800px;
    height: 474px;
    top: 50%;
    margin-top: -266px;
  }
  .image3 {
    padding-right: 10%;
    background: url("https://t.alipayobjects.com/images/T1nx0kXdFfXXXXXXXX.png") no-repeat right / 639px;
    background-origin: content-box;
    height: 500px;
    top: 50%;
    margin-top: -250px;
  }
  .image4 {
    background: url("https://t.alipayobjects.com/images/T1lyJkXg4aXXXXXXXX.png") no-repeat center / 615px;
    height: 364px;
    margin: auto;
  }
  .text-wrapper-bottom {
    text-align: center;
    margin: 10% auto 40px;
  }
  .text-wrapper-bottom p {
    margin: 20px auto;
    font-size: 16px;
    line-height: 28px;
    color: #999;
  }
  